import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import styledNormalize from 'styled-normalize';
import { media } from '../utils/style';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'La Ronda';
    src: url('../fonts/LaRonda-Regular.woff2') format('woff2'),
        url('../fonts/LaRonda-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  /* eb-garamond-regular - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/eb-garamond-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/eb-garamond-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/eb-garamond-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/eb-garamond-v15-latin-regular.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-800 - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/eb-garamond-v15-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/eb-garamond-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/eb-garamond-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-800.woff') format('woff'), /* Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/eb-garamond-v15-latin-800.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/eb-garamond-v15-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/eb-garamond-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/eb-garamond-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/eb-garamond-v15-latin-italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }
  /* eb-garamond-800italic - latin */
  @font-face {
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/eb-garamond-v15-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/eb-garamond-v15-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/eb-garamond-v15-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-800italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/eb-garamond-v15-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/eb-garamond-v15-latin-800italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
  }

  /* quicksand-300 - latin */
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/quicksand-v22-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/quicksand-v22-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/quicksand-v22-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/quicksand-v22-latin-300.woff') format('woff'), /* Modern Browsers */
        url('../fonts/quicksand-v22-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/quicksand-v22-latin-300.svg#Quicksand') format('svg'); /* Legacy iOS */
  }

  ${reset}
  ${styledNormalize}

  html {
    max-width: 100%;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  body, input, select, textarea {
    font-size: 14pt;
    line-height: 1.5;
    font-family: 'EB Garamond';
    color: #333;
    ${media.xs`
      font-size: 12pt;
    `};
  }

  p {
    font-size: 14pt;
    margin-bottom: 1em;
    color: #333;
    line-height:1.5em; 
  }
  p:last-child {
      margin-bottom:0;
    }

  p a {
    color: #405577;
    font-weight:900;
  }

  .inline-gatsby-image-wrapper {
    line-height:0;
    vertical-align: bottom;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    font-family: 'EB Garamond';
    font-size: 1em;
    margin-bottom: 1em;
    margin-left: 2em;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #292929;
    margin-top:0;
    margin-bottom: 0.5em;
  }
`;

export default GlobalStyles;
